function draw() {
    checkOrientation(window.innerWidth, window.innerHeight);
    if (redrawBackground) drawBackground();
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    checkNewFish();
    checkNewBubble();
    animateCrab();
    ctx.save();
    checkDrawBubble();
    drawImage(images[1], 600, 480, 0, 1);
    drawImage(images[2], 200, 430, 0, 1);
    checkDrawFish();
    drawCrab();
    ctx.restore();

    if (!running) {
        return
    }
    requestAnimationFrame(draw);
}

function drawBackground() {
    backgroundCtx.clearRect(0, 0, canvasWidth, canvasHeight);
    drawImage(images[0], 400, 300, 0, 1, 1, backgroundCtx);
    redrawBackground = false;
}


function animateFishes() {
    fishList.forEach(function(fish) {
        animate(fish);
    });
}


function animateCrab() {
    let currentImage = crab.image;
    animate(crab);
    if(crab.image != currentImage){
        crab.xPos += 20;
        if(crab.xPos > 2000) {
            crab.xPos = -260;
        }
    }
}


function drawCrab(){
    if(Math.abs(crab.xPos - (canvasWidth /2)) < 650 ) {
        drawImage(images[crab.image], crab.xPos, crab.yPos, 0, 1);
    }  
}


function animate(item) {
    item.frame += 1 / item.frameSpeed;
    let fs = item.frameList;
    let currentFrame = fs[Math.floor(item.frame % fs.length)]
    item.image = item.baseImage + currentFrame;
}


function completeUrl(urlList,) {
    if (urlList === null) return;
    return urlList.map(URL => require('@/assets/images/fishBowl/' + URL)) ;
}


function drawImage(image, x, y, rotation, scale = 1, direction = 1, defineCtx = ctx) {
    defineCtx.setTransform((scale * screenSize) * direction, 0, 0, (scale * screenSize), x * screenSize, y * screenSize); 
    defineCtx.drawImage(image, (-image.width) / 2, (-image.height) / 2);
} 


function drawFish(fish) {
    fish.xPos += fish.speed
    drawImage(images[fish.image], fish.xPos, fish.yPos, 0 , fish.size, fish.direction)
}


function drawBubble(bubble) {
    bubble.yPos -= bubble.speed;
    bubble.xPos += getRandomInt(0, 5) - 3;
    bubble.size += 0.01;
    drawImage(images[bubble.image], bubble.xPos, bubble.yPos, 0 , Math.round(bubble.size))
}


function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); 
}


function makeFish() {
    let fishDir = Math.random() > 0.5 ? 1 : -1;
    return {
        image: 4,
        direction: fishDir,
        xPos: (canvasWidth / (2 * screenSize)) - ((canvasWidth / (2 * screenSize)) * fishDir),
        yPos: getRandomInt(50, 350),
        speed: getRandomInt(2, 6) * fishDir,
        size: getRandomInt(1, 3) * screenSize,
        baseImage: 4,
        frame: 0,
        frameSpeed: 25,
        frameList: [0, 1, 0, 0, 1, 0, 0, 2, 2, 3, 2, 2],
    }
}


function makeBubble() {
    return {
        image: 3,
        xPos: getRandomInt(500, 650),
        yPos: 450,
        speed: 3,
        size: (getRandomInt(10, 20) / 10) * screenSize,
    }
}


function checkNewFish() {
    if (fishDelay > 0) {
        fishDelay -= 1;
    } else {
        fishDelay = getRandomInt(100, 150);
        fishList.push(makeFish());
    }
}


function checkNewBubble() {
    if (bubbleDelay > 0) {
        bubbleDelay -= 1;
    } else {
        bubbleDelay = getRandomInt(70, 150);
        bubbleList.push(makeBubble());
    }
}


function checkDrawFish() {
    fishList = fishList.filter(function(item){
        return Math.abs(item.xPos - ((800 * screenSize) / 2)) < 650; //checks if fish on canvas
    })
    fishList.forEach(function(fish){
        animateFishes();
        drawFish(fish);
    });  
}


function checkDrawBubble() {
    bubbleList = bubbleList.filter(function(item){
        return item.yPos > 0;
    })
    bubbleList.forEach(function(bubble){
        drawBubble(bubble);
    });  
}

function loadImages() {
    imageURL = [...completeUrl(miscImageURL)];
    imageURL.forEach(function(src) { 
        const image = new Image();
        image.src = src;
        image.onload = function() { 
            imageCount += 1;
            if(imageCount === imageURL.length){ 
                drawBackground();
                requestAnimationFrame(draw); 
            }
        }
        images.push(image); 

    });
}

function main() {
    if (running) {
        console.log('An instance of this programme is already running')
        return;
    }
    running = true;
    
    images = [];
    crab.xPos = -260,
    crab.yPos = 515,
    fishList = [];
    bubbleList= [];
    canvas = document.getElementById('fish-bowl') 
    ctx = canvas.getContext('2d');
    backgroundCanvas = document.getElementById('background');
    backgroundCtx = backgroundCanvas.getContext('2d');
    checkOrientation(window.innerWidth, window.innerHeight)
    loadImages()
}

function checkOrientation(windowWidth, windowHeight) {
    let isCurrentlyLandscape =  windowWidth > windowHeight;
    if(isCurrentlyLandscape === isLandscapeOrientation) return;

    parentContainer = document.getElementsByClassName('aspect-ratio')[0];
    screenSize = Math.floor((document.getElementById('animation').offsetWidth / 800) * 1000) /1000;
    if(isCurrentlyLandscape && 600 * screenSize > windowHeight)  screenSize = windowHeight / 600;
    const computedWidth = 800 * screenSize
    const computedHeight = 600 * screenSize;
    parentContainer.style.width = computedWidth + 'px';
    canvas.width = computedWidth;
    canvas.height = computedHeight;
    backgroundCanvas.width = computedWidth;
    backgroundCanvas.height = computedHeight;
    redrawBackground = true;
    isLandscapeOrientation = isCurrentlyLandscape;
}

function cleanUp() {
    running = false;
    imageCount = 0;
}

let running = false;
let isLandscapeOrientation = '';
let canvas;
let parentContainer;
let ctx;
let redrawBackground = true;
let backgroundCanvas;
let backgroundCtx;
let screenSize = 1;
let canvasWidth = 800 * screenSize;
let canvasHeight = 600 * screenSize;
let fishList = [];
const miscImageURL = ["backdrop.png", "castle.svg", "rock.svg", "bubble.svg", "fishBase.svg", "fishMove.svg", "fishBlinkOne.svg",
     "fishBlinkTwo.svg", "crabWalkOne.svg", "crabWalkTwo.svg", "crabBlink.svg"];
let imageURL = [];
let images = []; 
let imageCount = 0;
let fishDelay = 50;
let bubbleList = [];
let bubbleDelay;
let crab = {
    image: 8,
    xPos: -260,
    yPos: 515,
    baseImage: 8,
    frame: 0,
    frameSpeed: 20,
    frameList: [0, 1],
    delay: getRandomInt(300,500),
}

export default main;
export {cleanUp};

