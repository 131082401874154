<template>
    
    <div id="animation">
        <div class="aspect-ratio">
                <div class="ratio-container">
                    <canvas id="background" width="800" height="600"></canvas> 
                    <canvas id="fish-bowl" width="800" height="600"></canvas>
                            
                    <div class="play-overlay-container">
                        <transition name="fade">
                            <div v-if="!isHidden" @click="play" class="play-overlay">
                                <img class="play-overlay-image" :src="require('@/assets/images/fishPlayOverlay.png')">
                                <img class="arrow" :src="require('@/assets/images/arrowLarge.svg')">
                            </div>
                        </transition>
                    </div>
                </div>
        </div>
    </div>

</template>

<script>
import FishBowl, {cleanUp} from "../gameFiles/fishBowl";
export default {
    name: 'FishBowl',
    data() {
        return {
            isHidden: false,
        }
    },
    methods: {
        play() {
            this.isHidden = true;
            FishBowl(window.innerWidth, window.innerHeight);
       },
       makeFullScreen() {
           document.getElementById("animation").requestFullscreen();
       }
    },
    beforeDestroy() {
        cleanUp();
    }
}
</script>

<style lang="scss" scoped>

    canvas {
        position: absolute;
        top: 0;
        left: 0;
    }

    #animation {
        display: flex;
        justify-content: center;
        position: relative;
        max-width: 800px;
        max-height: 600px;
        width: 100%;
        height: 100%;
    }

    .play-overlay-container {
        position: relative;
    }

    .play-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;

        .play-overlay-image {
            width: 100%;
        }
    }

    .aspect-ratio {
        width: 100%;
        height: 0px;
        padding-bottom: 75%;
        position: relative;
    }

    .ratio-container {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .arrow {
        position: absolute;
    }
    
    .fade-enter-active, .fade-leave-active {
        transition: opacity .8s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

</style>