<template>
    
<div>
  <ProjectPageTemplate
    projectTitle="FISHBOWL"
    :imageSource="require('@/assets/images/projectLinks/fishBowlLink2.png')"
    shortDescription="Click play to watch the fish and other sealife swim by in a vector graphic animation. Using a HTML canvas this is a program written in JavaScript. Originally an animation I made as a scratch project, creating this was a useful exercise in learning the how to display and animate images in JavaScript and on a HTML canvas. "
    longDescription="All the images were SVG files drawn in scratch. Will look to add further elements from the original project and full screen option.<br>
                    <br>This has been updated so that the canvas will scale on smaller screens so it can now be viewed on mobile, and the height fits within the screen. The outer div will resize if it is smaller than the maximum width of the canvas. The window width and height is checked to determine if the screen is in portrait or landscape orientation.  If portrait (likely mobile), the div element's outerWidth is set as the canvas width and also used to calculate the height using the aspect ratio. If landscape, then the maximum height will be the screen's height and the width is again calculated against the aspect ratio of this.<br>
                    <br>As part of the animation loop the height and width ratio is checked to see if the screen has been rotated from its original position and will readjust the scale if this is the case."
    :importedLinkData="techLinksData"
  >
    <template v-slot:main-content>
      <div id="content-container">
          <fish-bowl></fish-bowl>

      </div>
    </template>
  </ProjectPageTemplate>
</div>
</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import FishBowl from "@/components/fishBowl.vue"
import techLinks from '@/data/techLinks.js'
export default {
  name: 'fishBowlAnimation',
  components: {
      FishBowl,
      ProjectPageTemplate,
  },
  data() {
    return {
      techLinksData: [
        techLinks.html, 
        techLinks.javascript,
        techLinks.scratch
      ],
    }
  }
}

</script>

<style lang="scss" scoped>

#content-container {
  width: 100%;
  display: flex;
  justify-content: center;

}

</style>