<template>

<div class="main-section-container">
    <div class="inner-page-container">
        <h1 class="site-heading">{{projectTitle}}</h1>
        
        <div class="iframe-container">
            <slot name="main-content"></slot>
        </div>

        <div class="info-container">
            <div class="description-container">
                <div class="description">
                    <p>{{shortDescription}}</p>
                </div>
            </div>

            <div class="project-image">
                <img :src="imageSource">
            </div>

        </div>
        <div class="details-container">
            <div class="details-title more-details" @click="toggleDetails">
                <DetailsArrow :isSelected="arrowClicked" />
                <p>{{arrowClicked ? 'Less Details' : 'More Details'}}</p>
            </div>

            <div class="details-title programs-used" @click="toggleDetails">
                <p>Tech used</p>
            </div>

            <div class="body-container" :class="{open: arrowClicked}">
                 <div>
                    <p v-html="longDescription"></p>
                </div>
            </div>

            <div class="body-container" :class="{open: arrowClicked}">
                <div class="tech-links">
                    <ExternalLink v-for="linkData in importedLinkData"
                        :linkDetails="linkData"
                        :key="linkData.index"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import ArrowToggle from "@/mixins/ArrowToggle";
import DetailsArrow from "@/components/page_elements/DetailsArrow";
import ExternalLink from "@/components/ExternalLink.vue"

export default {
    name: 'ProjectPageTemplate',
    mixins: [ArrowToggle],
    components: {
        DetailsArrow,
        ExternalLink,
    },
    props: {
        imageSource: {
            required: true,
            type: String,
        },
        projectTitle: {
            default: '',
            type: String,
        },
        shortDescription: {
            default: '',
            type: String,
        },
        longDescription: {
            default: '',
            type: String,
        },
        importedLinkData: {
            type: Array,
        }
    },
}
</script>

<style lang="scss" scoped>
    $tablet-size: 860px;

    .iframe-container {
        display: flex;
        justify-content: center;

        .iframe {
            width: 100%;
            height: 600px;
            margin-bottom: 10px;
            max-width: 800px;
        }
    }

    .info-container {
        display: flex;
        margin: 15px 0px;

        @media only screen and (max-width: $tablet-size) {
            flex-direction: column;
            align-items: center;

           .description-container.description-container {
               width: 100%;
               margin-bottom: 15px;
               padding: 0;
               
           }
        }

        .description-container {
            padding-right: 15px;
            flex-grow: 1;

            .description {
                background: white;
                border-radius: 0px 0px 25px 25px;
                width: 100%;
                height: 100%;
                padding: 5px;
            }
        }

        .project-image {
            width: 250px;
            height: 190px;
            flex-shrink: 0;

            img {
                width: 100%;
            }

            @media only screen and (max-width: $tablet-size) {
                display: none;
            }
        }
    }

    .details-container {
        display: grid;
        grid-template-columns: 6fr 4fr;
        column-gap: 20px;
        

        @media only screen and (max-width: $tablet-size) {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto auto;
            grid-auto-flow: row;
        }
    }

    .details-titles-container {
        display: flex;
        width: 100%;
    }

    .details-title {
        display: flex;
        background: var(--color3);
        
        cursor: pointer;
        justify-content: center;
        font-weight: bold;
        font-size: 25px;
        color: #000;
        text-transform: uppercase;
        text-align: center;
        
        @media only screen and (max-width: $tablet-size) {
            &:not(:first-of-type) {
                margin-top: 20px;
            }
        }
        p {
            width: 100%;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .body-container {
        background: white;
        border-radius: 0px 0px 25px 25px;
        padding: 5px;
        display: none;
        width: 100%;

        @media only screen and (max-width: $tablet-size) {
            grid-row-start: 2;
            grid-column-start: 1;
            &:last-of-type {
                grid-row-start: 4;
                grid-column-start: 1;
            }
        }
        &.open {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .tech-links {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>