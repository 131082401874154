export default {
    data() {
        return {
            arrowClicked: false,
        }
    },
    methods: {
        toggleDetails() {
            this.arrowClicked = !this.arrowClicked;
        },
        closeDetails() {
            this.arrowClicked = false;
        },
    },
    // created() {
    //     window.addEventListener("resize", this.closeDetails);
    // },
    destroyed() {
        window.removeEventListener("resize", this.closeDetails);
    },
}