<template>
    <div class="details-arrow-container">
        <img class="details-arrow" :class="{open: isSelected}" src="@/assets/images/dropDownButton.svg">
    </div>
</template>

<script>
export default {
    name: 'DetailsArrow',
    props: {
      isSelected: {
          required: true,
          type: Boolean,
      },
    },
}
</script>

<style lang="scss" scoped>
  .details-arrow {
      cursor: pointer;
      transition: transform 0.3s;
      border: 4px solid #000;
      padding: 2px;
      background: rgb(255, 220, 20);

      &.open {
          transform: scaleY(-1)
      }
  }

  .details-arrow-container {
    display: flex;
  }

</style>