<template>
    <a class="tech-container">
        <div class="image-container" >
            <img class="link-image" :src="require(`@/assets/images/linkIcons/${linkDetails.imgSrc}`)">
        </div>
        <div class="link-text"> {{linkDetails.name}}</div>
    </a>
</template>

<script>
export default {
    name: 'ExternalLink',
    props: {
        linkDetails: {
            type: Object,
            default: () => null,
        },
    }
}
</script>

<style scoped>
.tech-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100px;
    margin-top: 10px;
    margin-left: 25%;
}

.image-container {
    display: flex;
    justify-content: left;
}

.link-text {
    width: 80%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 15px;
    font-size: 25px;
    font-weight: bold;
}
</style>